$link-color: #7d0d6a;

.nav-bar {
  // background: #181818;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 110px;
  position: absolute;
  top: 0;
  z-index: 3;
  //   min-height: 500px;

  .logo {
    // display: flex;
    padding: 6px;

    img {
      //   display: flex;
      margin-left: 10px;
      width: auto;
      height: 100px;
    }
  }

  nav {
    display: flex;
    // justify-content: center;
    // align-items: center;
    // position: absolute;

    // height: 210px;
    // top: 50%;
    // margin-top: -120px;
    // width: 100%;

    a {
      font-size: 25px;
      color: #4d4d4e;
      //   display: block;
      // line-height: 51px;
      // height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #7d0d6a;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &::after {
          content: 'HOME';
          font-size: 18px;
          font-weight: 700;
          //   justify-content: center;
          margin-left: -30px;
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PROJECTS';
      }
    }

    a.skills-link {
      &:after {
        content: 'SKILLS';
      }
    }

    a.resume-link {
      &:after {
        content: 'RESUME';
      }
    }

    a.active {
      svg {
        color: #7d0d6a;
      }
    }
  }

  ul {
    // position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    padding: 8px;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 30px;
        // line-height: 16px;
        color: #4d4d4e;
      }

      &:hover {
        .anchor-icon {
          color: #7d0d6a;
        }
      }
    }
  }
}

.navLink {
  padding-right: 25px;
  padding-left: 25px;
  justify-items: center;
}

.hamburger-icon,
.close-icon {
  display: none;
}

.hamburger-icon,
.close-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    min-height: auto;

    ul,
    nav {
      display: none;
    }

    nav {
      width: 100%;
      top: 0;
      left: 0;
      padding-top: 20px;
      background: linear-gradient(
        0.5turn,
        #ffffff,
        rgba(250, 240, 242),
        rgba(223, 254, 255)
      );
      font-size: 18px;
      height: 100%;
      margin: 0;
      z-index: 2;
      position: fixed;

      &.mobile-show {
        display: inline-block;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a:after {
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a svg {
        opacity: 0.1 !important;
      }
    }

    .hamburger-icon,
    .close-icon {
      position: absolute;
      top: 15px;
      right: 15px;
      display: block;
    }
  }
}
