.portfolio-page {
  padding-left: 100px;
  padding-right: 50px;
  width: 100%;
  position: initial;
  height: calc(100% - 150px);
  overflow: auto;

  h1 {
    font-size: 53px;
    font-family: 'Coolvetica';
    color: #001d46;
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 40px;

    &:before {
      font-family: 'La Belle Aurore', cursive;
      color: #001d46;
      font-size: 18px;
      position: absolute;
      margin-top: -10px;
      left: -10px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      font-family: 'La Belle Aurore', cursive;
      color: #7d0d6a;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: -30px;
      bottom: -20px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  p {
    font-size: 13px;
    color: #fff;
    font-family: sans-serif;
    font-weight: 300;
    // max-width: fit-content;
    animation: pulse 1s;
    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }
    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}

.images-container {
  display: flex;
  gap: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 100px;
}

.image-box-left,
.image-box-mid,
.image-box-right {
  position: relative;
  flex: 30%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  max-width: calc(25% - 10px);

  .portfolio-image {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    width: 100%;
    z-index: 3;
    padding: 10px 20px 1px 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0, rgba(0, 0, 0, 1));
    bottom: -70px;
  }

  .title {
    margin-bottom: 0;
    margin-top: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }

  .description {
    font-size: 18px;
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    color: #fff;
    font-weight: 700;
  }

  .btn {
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 0 23px;
    height: 40px;
    line-height: 34px;
    border: 2px solid #001d46;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background: transparent;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
  }

  .btn:hover {
    transform: translateY(-3px);
    background: #7d0d6a;
  }

  &:after {
    content: '';
    background: linear-gradient(180deg, #7d0d6a, #000);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
  }

  &:hover:after {
    opacity: 0.85;
  }

  &:hover .content {
    bottom: 0;
    background: transparent;
  }
}
